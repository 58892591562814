import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`📖 FAQ`}</h1>
    <ol>
      <li parentName="ol">{`What is `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{`?`}
        <ul parentName="li">
          <li parentName="ul">{`See: `}<a parentName="li" {...{
              "href": "/Polis",
              "title": "Polis"
            }}>{`Polis`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ol">{`Is `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` a survey?`}
        <ul parentName="li">
          <li parentName="ul">{`A `}<a parentName="li" {...{
              "href": "/Wikisurvey",
              "title": "💡 Wikisurvey"
            }}>{`💡 Wikisurvey`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`What is `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` `}<strong parentName="li">{`not`}</strong>{`?`}
        <ul parentName="li">
          <li parentName="ul">{`A `}<a parentName="li" {...{
              "href": "/tool",
              "title": "tool"
            }}>{`tool`}</a>{` for gathering the 'best' idea, or comparing ideas head to head.`}</li>
          <li parentName="ul">{`A `}<a parentName="li" {...{
              "href": "/tool",
              "title": "tool"
            }}>{`tool`}</a>{` for gathering specific questions like "how old are you" and "where do you live"`}</li>
          <li parentName="ul">{`A `}<a parentName="li" {...{
              "href": "/tool",
              "title": "tool"
            }}>{`tool`}</a>{` for gathering data from groups of less than 50 to 100 people`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Can I embed `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` on my site?`}
        <ul parentName="li">
          <li parentName="ul">{`Yes, this is preferred to legitimize your `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{` and build trust with `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{`. See: `}<a parentName="li" {...{
              "href": "/Embedding-Polis-on-Your-Web-Property",
              "title": "Embedding Polis on Your Web Property"
            }}>{`Embedding Polis on Your Web Property`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ol">{`Is `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` open source?`}
        <ul parentName="li">
          <li parentName="ul">{`Yes. `}<a parentName="li" {...{
              "href": "https://github.com/compdemocracy/polis"
            }}>{`https://github.com/compdemocracy/polis`}</a></li>
          <li parentName="ul">{`Both platform and data science analysis notebooks are open source`}</li>
        </ul>
      </li>
      <li parentName="ol">{`I've heard `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` promotes `}<a parentName="li" {...{
          "href": "/consensus",
          "title": "consensus"
        }}>{`consensus`}</a>{` over polarization. Is that true? If so, how?`}
        <ul parentName="li">
          <li parentName="ul">{`It's certainly possible that a group of 1000 participants could join a `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{`, submit 100 `}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{`, and `}<a parentName="li" {...{
              "href": "/vote",
              "title": "vote"
            }}>{`vote`}</a>{` completely evenly, with a 50 - 50 split on every comment: totally polarized. There's nothing preventing this from happening.`}</li>
          <li parentName="ul">{`The funny thing is, it never seems to. There's always a distribution of topics and subtopics that emerge. There are always comments submitted which capture a majority, or a supermajority, or nearly everyone in the entire conversation as having the same viewpoint.`}</li>
          <li parentName="ul">{`In practice, this means that as the `}<a parentName="li" {...{
              "href": "/Algorithms",
              "title": "👾 Algorithms"
            }}>{`👾 Algorithms`}</a>{` find `}<a parentName="li" {...{
              "href": "/opinion-groups",
              "title": "opinion groups"
            }}>{`opinion groups`}</a>{` and surface differences, one can then consider what they have in common, and which statements were supported or rejected commonly by all `}<a parentName="li" {...{
              "href": "/opinion-groups",
              "title": "opinion groups"
            }}>{`opinion groups`}</a>{`.`}</li>
          <li parentName="ul">{`See: `}<a parentName="li" {...{
              "href": "/integrating-results-from-Polis",
              "title": "integrating results from Polis"
            }}>{`integrating results from Polis`}</a>{` into decisionmaking processes]]`}</li>
          <li parentName="ul">{`See: `}<a parentName="li" {...{
              "href": "/consensus",
              "title": "consensus"
            }}>{`consensus`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ol">{`Can I deploy my own instance of `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{`? How easy is it to do that?`}
        <ul parentName="li">
          <li parentName="ul">{`It's been done, but it's still not easy and requires senior engineers experienced in cloud systems. See: `}<a parentName="li" {...{
              "href": "/hosted",
              "title": "hosted"
            }}>{`hosted`}</a>{` and `}<a parentName="li" {...{
              "href": "/deploying-Polis",
              "title": "💾 deploying Polis"
            }}>{`💾 deploying Polis`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ol">{`What are the maths behind `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{`?`}
        <ul parentName="li">
          <li parentName="ul">{`See: `}<a parentName="li" {...{
              "href": "/Algorithms",
              "title": "👾 Algorithms"
            }}>{`👾 Algorithms`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ol">{`Does `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` use natural language processing (NLP)?`}
        <ul parentName="li">
          <li parentName="ul">{`No. The machine learning `}<a parentName="li" {...{
              "href": "/Algorithms",
              "title": "👾 Algorithms"
            }}>{`👾 Algorithms`}</a>{` run are solely run on the `}<a parentName="li" {...{
              "href": "/polis-opinion-matrix",
              "title": "polis opinion matrix"
            }}>{`polis opinion matrix`}</a>{` of agrees, disagrees and passes by participants on comments. Thus, `}<a parentName="li" {...{
              "href": "/Polis",
              "title": "Polis"
            }}>{`Polis`}</a>{` is language agnostic.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`What about profanity and hate? Do I need to moderate `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` `}<a parentName="li" {...{
          "href": "/conversations",
          "title": "conversations"
        }}>{`conversations`}</a>{`?`}
        <ul parentName="li">
          <li parentName="ul">{`Yes. See: `}<a parentName="li" {...{
              "href": "/Moderation",
              "title": "👹 Moderation"
            }}>{`👹 Moderation`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ol">{`Is the main hosted instance available at `}<a parentName="li" {...{
          "href": "/pol.is",
          "title": "pol.is"
        }}>{`pol.is`}</a>{` `}<a parentName="li" {...{
          "href": "/GDPR",
          "title": "GDPR"
        }}>{`GDPR`}</a>{` compliant?`}
        <ul parentName="li">
          <li parentName="ul">{`Yes. `}<a parentName="li" {...{
              "href": "/about"
            }}>{`CompDem`}</a>{` has worked with counsel in Europe and the United States to ensure that pol.is/tos and pol.is/privacy are compliant`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Can `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` break down opinions based on demographic or location information?`}
        <ul parentName="li">
          <li parentName="ul">{`Yes, if you're collecting this data yourself. You can link a participation record to a random long number which links back to your user records, and merge the tables after the conversation ends. Thus, the data `}<a parentName="li" {...{
              "href": "/export",
              "title": "export"
            }}>{`export`}</a>{` from `}<a parentName="li" {...{
              "href": "/Polis",
              "title": "Polis"
            }}>{`Polis`}</a>{` can be connected to your data.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`What makes a `}<a parentName="li" {...{
          "href": "/good-prompt",
          "title": "good prompt"
        }}>{`good prompt`}</a>{` for pol.is?`}</li>
      <li parentName="ol">{`Why are the options `}<a parentName="li" {...{
          "href": "/agree",
          "title": "agree"
        }}>{`agree`}</a>{`, `}<a parentName="li" {...{
          "href": "/disagree",
          "title": "disagree"
        }}>{`disagree`}</a>{` and `}<a parentName="li" {...{
          "href": "/pass",
          "title": "pass"
        }}>{`pass`}</a>{`? Why are those important to the way that `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` works?`}
        <ul parentName="li">
          <li parentName="ul">{`Structured replies also eliminate the possibility of trolling by replying directly (and thus provoking further response).`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Does `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` visualize the debate as it's ongoing? Is this just for the `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` `}<a parentName="li" {...{
          "href": "/owner",
          "title": "owner"
        }}>{`owner`}</a>{` or for all `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{`?`}
        <ul parentName="li">
          <li parentName="ul">{`Yes, the `}<a parentName="li" {...{
              "href": "/report",
              "title": "report"
            }}>{`report`}</a>{` is in real-time and available to the `}<a parentName="li" {...{
              "href": "/owner",
              "title": "owner"
            }}>{`owner`}</a>{` via the `}<a parentName="li" {...{
              "href": "/admin-interface",
              "title": "admin interface"
            }}>{`admin interface`}</a>{`. The `}<a parentName="li" {...{
              "href": "/report",
              "title": "report"
            }}>{`report`}</a>{` uses long link privacy, like google docs does. You are welcome to share it with `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` at any time.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Is the interface complicated for `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{`?`}
        <ul parentName="li">
          <li parentName="ul">{`No. Participants only have two options: they can submit a `}<a parentName="li" {...{
              "href": "/comment",
              "title": "comment"
            }}>{`comment`}</a>{`, or they can `}<a parentName="li" {...{
              "href": "/vote",
              "title": "vote"
            }}>{`vote`}</a>{` on `}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{` submitted by other `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{`.`}</li>
          <li parentName="ul">{`Across all our `}<a parentName="li" {...{
              "href": "/conversations",
              "title": "conversations"
            }}>{`conversations`}</a>{`, we've seen a roughly 10:1 ratio of voters to commenters. Ie., there are roughly 10 people who vote on statements for every 1 who comments. This higher engagement comes from providing a simple, low friction interface: participants can communicate their position via voting without needing to summarize it.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Who needs to be involved to make it effective?`}
        <ul parentName="li">
          <li parentName="ul">{`See: `}<a parentName="li" {...{
              "href": "/Roles",
              "title": "👷🏾👷 Roles"
            }}>{`👷🏾👷 Roles`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ol">{`Does `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` `}<a parentName="li" {...{
          "href": "/scale",
          "title": "scale"
        }}>{`scale`}</a>{`? To what degree?`}
        <ul parentName="li">
          <li parentName="ul">{`Yes. There's technically no limit to how many people can participate in a `}<a parentName="li" {...{
              "href": "/Polis",
              "title": "Polis"
            }}>{`Polis`}</a>{` `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{`. To date, our largest conversation took place in Germany, with over 33,000 participants who generated nearly 2,000,000 votes on 1,000 comments. With random sampling, this is more than enough scale to sample any size population.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`How do I get started?`}
        <ul parentName="li">
          <li parentName="ul">{`Create an account at `}<a parentName="li" {...{
              "href": "/pol.is",
              "title": "pol.is"
            }}>{`pol.is`}</a>{`, start a `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{`, add some `}<a parentName="li" {...{
              "href": "/seed-comments",
              "title": "seed comments"
            }}>{`seed comments`}</a>{` and share a link or embed the interface on your own page.`}</li>
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "/Usage",
              "title": "🔩 Usage"
            }}>{`🔩 Usage`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ol">{`Does `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` work in multiple languages?`}
        <ul parentName="li">
          <li parentName="ul">{`Yes. The `}<a parentName="li" {...{
              "href": "/Participation-Interface",
              "title": "Participation Interface"
            }}>{`Participation Interface`}</a>{` has been translated into several languages. See `}<a parentName="li" {...{
              "href": "/interface-translations",
              "title": "interface translations"
            }}>{`interface translations`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Are `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` sent out randomly?`}
        <ul parentName="li">
          <li parentName="ul">{`No. They are sent out `}<a parentName="li" {...{
              "href": "/semi-randomly",
              "title": "semi-randomly"
            }}>{`semi-randomly`}</a>{` in a process `}<a parentName="li" {...{
              "href": "/Polis",
              "title": "Polis"
            }}>{`Polis`}</a>{` calls `}<a parentName="li" {...{
              "href": "/comment-routing",
              "title": "comment routing"
            }}>{`comment routing`}</a>{` or `}<a parentName="li" {...{
              "href": "/comment-prioritization",
              "title": "comment prioritization"
            }}>{`comment prioritization`}</a>{`.`}</li>
          <li parentName="ul">{`The prioritization is based on divisiveness — `}<a parentName="li" {...{
              "href": "/consensus",
              "title": "consensus"
            }}>{`consensus`}</a>{` statements are lower in information for forming groups, so comments that are instructive to the formation of groups (higher statistical variance) are prioritized.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`How much is automatic? How much is required of people?`}
        <ul parentName="li">
          <li parentName="ul">{`Polis can be fully automatic after a prompt and `}<a parentName="li" {...{
              "href": "/seed-comments",
              "title": "seed comments"
            }}>{`seed comments`}</a>{` are set. See: `}<a parentName="li" {...{
              "href": "/Moderation",
              "title": "👹 Moderation"
            }}>{`👹 Moderation`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ol">{`Are there ways to game `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{`?`}
        <ul parentName="li">
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "/extremism",
              "title": "extremism"
            }}>{`extremism`}</a>{``}</li>
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "/gaming-the-system",
              "title": "gaming the system"
            }}>{`gaming the system`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ol">{`What are the `}<a parentName="li" {...{
          "href": "/tradeoffs-and-weaknesses-of-the-methodology",
          "title": "tradeoffs and weaknesses of the methodology"
        }}>{`tradeoffs and weaknesses of the methodology`}</a>{`?`}
        <ul parentName="li">
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "/tradeoffs-and-weaknesses-of-the-methodology",
              "title": "tradeoffs and weaknesses of the methodology"
            }}>{`tradeoffs and weaknesses of the methodology`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ol">{`What are the `}<a parentName="li" {...{
          "href": "/criticisms-of-the-methodology",
          "title": "criticisms of the methodology"
        }}>{`criticisms of the methodology`}</a>{`?`}
        <ul parentName="li">
          <li parentName="ul">{`See: `}<a parentName="li" {...{
              "href": "/criticisms-of-the-methodology",
              "title": "criticisms of the methodology"
            }}>{`criticisms of the methodology`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ol">{`Why does survey tech have `}<a parentName="li" {...{
          "href": "/implications-for-democracy",
          "title": "implications for democracy"
        }}>{`implications for democracy`}</a>{` and democratic practices?`}
        <ul parentName="li">
          <li parentName="ul">{`See: `}<a parentName="li" {...{
              "href": "/implications-for-democracy",
              "title": "implications for democracy"
            }}>{`implications for democracy`}</a>{``}</li>
        </ul>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      